.App {
  text-align: center;
  background-color: #b9b7b7;
  width: 100%;
  height: 100%;
  animation: fadeInAnimation 1s;
  scroll-behavior: smooth;
}



@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}