#portfolio {
    margin-top: 4.5rem;
    background-image: url(./img/white-rectangle.png);
    image-rendering: -o-crisp-edges; 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% .1%;
}

#portfolio h3{
    width: 16rem;
    height: 3rem;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    flex-shrink: 0;
    flex-shrink: 0;
    color: white;
    background: #4650AB;
    text-align: center;
    font-family: 'Hubballi', cursive;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-right: .5rem;
    padding-bottom: .5rem;
    margin-left: 2%;
    margin-bottom: 0;
    border-top: 5px solid black;
    border-left: 10px solid black;
}

#button-section {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    margin: 0rem 24% 3rem 24%;
}

#button-section button {
    width: 12rem;
    padding: .5rem;
    margin: .25rem 0;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Hubballi', sans-serif;
    font-size: 1.35rem;
    letter-spacing: .15rem;
    color: white;
    background: #d33a9bc8;
}

#button-section button:hover {
    animation: pulse 1s;
    cursor: pointer;
}

.pulse {
    animation: pulse 2s infinite ease-in-out alternate;
  }
  @keyframes pulse {
    from { transform: scale(1.0); }
    to { transform: scale(1.05); }
  }

/* MEDIA QUERIES */

@media (max-width: 31rem){
    #button-section button {
        width: 90%;
    }
}

@media (min-width: 50.25rem){
    #portfolio {
        background-image: url(./img/white-rectangle-tablet.png);
    }
}

@media (min-width: 69.3rem){
    #portfolio {
        padding-bottom: 12.5%;
    }
}

@media (min-width: 75.3rem){
    #portfolio {
        background-image: url(./img/white-rectangle-desktop.png);
    }
}

@media (min-width: 99.75rem){
    #portfolio {
        background-image: url(./img/white-rectangle-desktop-xlarge.png);
        padding-bottom: 0;
    }
}
