
#intro-section {
    background-image: url('./img/tablet-hero-shapes.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    height: 70vh;
    margin-top: 1rem;
    padding-top: 3rem;
}

#intro-section h1 {
    font-family: Bellota Text;
    background: #9a0061;
    color: rgb(255, 255, 255);
    width: 12rem;
    height: 6.5rem;
    flex-shrink: 0;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    text-align: center;
    font-size: 3.75rem;
    font-weight: 200;
    margin-left: 1rem;
    padding-left: 1rem;
}

#intro-section h2 {
    display: flex;
    padding: 1rem;
    margin-top: 2.5rem;
    margin-left: 20%;
    background: #FFF;
    color: #9a0061;
    font-family: Bellota Text;
    text-align: left;
    font-size: 1.17rem;
    font-style: normal;
    font-weight: 400;
}

#intro-section p {
    background: #4650AB;
    color: #FFF;
    font-family: Bellota Text;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    width: 16rem;
    height: 3rem;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: .5rem;
    margin-top: 2.25rem;
    margin-left: 1rem;
}

#line {
    position: relative;
    width: 100%;
    z-index: 1;
}

/* MEDIA QUERIES */

@media (max-width: 23rem){
    #intro-section p {
        width: 90%;
    }
}

@media (min-width: 34rem){
    #intro-section {
        padding-top: 4rem;
        height: 80vh;
    }

    #intro-section h1 {
        margin-left: 6%;
    }

    #intro-section h2 {
        width: 24rem;
        margin-left: 23%;
    }

    #intro-section p {
        margin-left: 3%;
        margin-top: 4rem;
        width: 24rem;
    }
}

@media (min-width: 42rem){
    #intro-section {
        padding-top: 5rem;
        height: 80vh;
    }

    #intro-section h1 {
        margin-left: 20%;
    }

    #intro-section h2 {
        margin-left: 30%;
    }

    #intro-section p {
        margin-left: 15%;
        margin-top: 3.5rem;
    }
}

@media (min-width: 52rem){
    #intro-section {
        padding-top: 6rem;
        padding-bottom: 5%;
    }

    #intro-section h2 {
        margin-left: 35%;
    }
}

@media (min-width: 60rem){
    #intro-section {
        padding-top: 6rem;
    }
}

@media (min-width: 76rem){
    #intro-section {
        background-position: 100% 30%;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

@media (min-width: 81rem){
    #intro-section {
        background-position: 0% 30%;
        padding-top: 3rem;
        padding-bottom: 12rem;
    }
}