#project-cards-container {
    margin-top: 1rem;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
}


/* Project Cards */

#project-card {
    font-family: 'Hubballi', cursive;
    border: 1px solid black;
    border-radius: .65rem;
    margin: 1.25rem 0;
    padding: 2rem 0rem;
    background: white;
    max-width: 21rem;
}

#project-card h1 {
    margin: 1rem 1rem 1.75rem 1rem;
    font-family: 'Hubballi', cursive;
    font-size: 1.75rem;
    font-weight: lighter;
}

#project-card img {
    height: 11rem;
    border-radius: .25rem;
    margin-bottom: 1rem;
    border: 1px solid black;
}

#project-description {
    text-align: justify;
    margin: 1rem 2.5rem 2rem 2.5rem;
}

#skills {
    text-decoration: underline;
    text-underline-offset: .25rem;
    margin-bottom: 2rem;
}

#project-card button {
    margin: 0rem 1rem 1rem 1rem;
    padding: .5rem 2rem;
    background: white;
    font-family: 'Hubballi', cursive;
    border: 1px solid black;
    border-radius: .25rem;
}

#project-card button:hover {
    animation: pulse 1s;
    cursor: pointer;
}
.pulse {
    animation: pulse 2s infinite ease-in-out alternate;
  }
  @keyframes pulse {
    from { transform: scale(1.0); }
    to { transform: scale(1.05); }
  }


/* MEDIA QUERIES */

@media (max-width: 22rem){
    #project-card {
        width: 90%;
    }
}

@media (min-width: 25rem){
    #project-card {
        margin: 1rem;
    }
}

@media (min-width: 34rem){
    #button-section {
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 0%;
        padding-bottom: 5%;
    }

    #project-cards-container {
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
    }

    #project-card {
        max-width: 23rem;
        height: 34rem;
    }

    #project-card h1{
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}
