a {
    text-decoration: none;
    color: white;
}

#footer-container {
    background: black;
}

#footer-content {
    background: black;
    color: white;
    font-family: 'Hubballi', sans-serif;
    width: 100%;
    height: 13rem;
    display: flex;
    justify-content: space-around;
}

#footer-content h3 {
    font-size: 1.35rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0.5rem;
}

#contact-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#contact-section a {
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
    margin-bottom: 1rem;
}

#contact-section p {
    margin-top: 0.5rem;
}

#social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#logos img {
    width: 2.35rem;
    margin: .2rem .35rem;
}

/* MEDIA QUERIES */

@media (max-width: 26rem){
    #footer-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #footer-content h3 {
        display: none;
    }

    #contact-section a {
        font-size: 1.1rem;
    }
}

@media (min-width: 40rem){
    #footer-content{
        margin: 0 3%;
        width: auto;
    }
}

@media (min-width: 50rem){
    #footer-content{
        margin: 0 7%;
        width: auto;
    }
}

@media (min-width: 60rem){
    #footer-content{
        margin: 0 12%;
        width: auto;
    }
}

@media (min-width: 70rem){
    #footer-content{
        margin: 0 18%;
        width: auto;
    }
}