.navigation {
  width: 90%;
  transform: translateX(5%);
  height: 3rem;
  background-color: #b9b7b7;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-bottom: 1rem;
}

#navigation-container {
  width: 100%;
  text-align: center;
}

.navigation h4 {
  color: #FFF;
  text-align: center;
  font-family: 'Hubballi', cursive;
  font-size: 1.55rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21rem;
  animation: fadeInAnimation 5s;
}

.navigation ul {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  padding: 0 0 2rem 0;
}

.navigation ul li {
  list-style-type: none;
  padding-right: .5rem;
}

#top-header-line a {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

#top-header-line img {
  width: 2rem;
  padding-right: .75rem;
  padding-top: .5rem;
}

.hamburger {
  margin-top: 7%;
  margin-right: 3%;
}

#navigation__list {
  position: absolute;
  top: 42%;
  left: 46%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding-right: 30px;
  z-index: 100;
}

label{
  display: flex;
  flex-direction: column;
  width: 1.5rem;
  cursor: pointer;
  top: 5%;
  z-index: 2;
  position: relative;
}
 
label span{
  border-radius: 10px;
  height: .2rem;
  margin: .15rem 0;
  transition: .4s;
}
 
span:nth-of-type(1){
  background: #4650AB;
  width: 60%;
}

label:hover >
span:nth-of-type(1){
  width: 100%;
}
 
span:nth-of-type(2){
  background: #d8dc06;
  width: 100%;
}
 
span:nth-of-type(3){
  background: #9a0061;
  width: 85%;
}

label:hover >
span:nth-of-type(3){
  width: 100%;
}

input[type="checkbox"]{
  display: none;
}

input[type="checkbox"]:checked ~ span:nth-of-type(1){
  transform-origin: bottom;
  display: none;
  transition: .4s;
  transform:rotatez(45deg);
}

input[type="checkbox"]:checked ~ span:nth-of-type(2){
  transform-origin: top;
  width: 100%;
  transform: rotatez(-45deg) translate(0px, 6px);
}

input[type="checkbox"]:checked ~ span:nth-of-type(3){
  transform-origin: bottom;
  width: 100%;
  transform: rotatez(45deg) translate(0px, -5px);
}

/* -- Side Nav Menu --*/

.hidden {
  display: none;
}

#sidebar-container{
  background: #4650AB;
  height: 100vh;
  margin: none;
  width: 120%;
  transform: translateX(-10%);
  position: absolute;
  z-index: 1;
  animation: fadeInAnimation .2s;
}

#navigation__list {
  width: 60%;
  list-style: none;
  line-height: 2.75rem;
  text-align: left;
  display: block;
  top: 35%;
  overflow: hidden;
  position: fixed;
  animation: fadeInAnimation 1s;
}

#navigation__list li {
  font-size: 1.75rem;
  margin: 2rem 0;
  letter-spacing: .15rem;
}

#navigation__list li a:hover {
  color: #c0c305;
}

/* MEDIA QUERIES */

@media (max-width: 22rem){
  .navigation {
      width: auto;
      padding-left: 1rem;
      transform: none;
  }
  label{
    padding-right: 1rem;
  }
}

@media (min-width: 28rem){
  .hamburger {
    margin-top: 4%;
    margin-right: 2%;
  }
}

@media (min-width: 48rem){
  .navigation {
    width: 94%;
  }

  .navigation ul li {
    padding-right: 0px;
  }

  .hamburger {
      display: none;
  }

  #navigation-row {
    display: inline-block;
    margin-right: 2rem;
  }

  #navigation-row a {
    font-family: 'Hubballi', cursive;
    font-size: 1.25rem;
    color: #810051;
  }

  #navigation-row a:hover {
    color: #4650AB;
  }

  #navigation-row ul li {
    padding: .5rem .75rem;
    text-align: center;
    font-family: Baloo 2;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}