#about {
    background: #b9b7b7;
    margin-top: 5rem;
    background-image: url("./img/headshot.png");
    background-size: 16rem;
    background-repeat: no-repeat;
    background-position: 93% 2%;
}

#about h3{
    color: rgb(0, 0, 0);
    background: #b1b401;
    width: 16rem;
    height: 3rem;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    text-align: center;
    font-family: 'Hubballi', cursive;
    font-size: 2rem;
    font-weight: 400;
    line-height: normal;
    padding-left: .5rem;
    padding-bottom: .5rem;
    margin-right: 25%;
    margin-left: auto;
    margin-bottom: 0;
    border-top: 5px solid black;
    border-right: 10px solid black;
}

#about img {
    width: 20rem;
    height: 19rem;
    margin-right: 18%;
}

#about-text {
    margin: 17.5rem 2.5rem 3.5rem 2.5rem;
}

#about-text p {
    color: #000;
    text-align: justify;
    font-family: 'Hubballi', cursive;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
}

#about-text a {
    color: #000;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.35rem;
}

#lang-proficiencies {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

#lang-proficiencies img {
    width: 20rem;
    height: auto;
    margin: 0 0 .75rem 0;
}

#resume-btn {
    width: 12rem;
    height: 1.25rem;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Hubballi', sans-serif;
    font-size: 1rem;
    letter-spacing: .15rem;
    color: black;
    margin-bottom: 2rem;
    background: #b1b40467;
    border: .5px solid #B0B404;
}

#resume-btn:hover {
    animation: pulse 1s;
    cursor: pointer;
}
.pulse {
    animation: pulse 2s infinite ease-in-out alternate;
  }
  @keyframes pulse {
    from { transform: scale(1.0); }
    to { transform: scale(1.05); }
  }


/* MEDIA QUERIES */

@media (max-width: 22rem){
    #lang-proficiencies img {
        width: 100%;
    }
}

@media (min-width: 34rem){
    #lang-proficiencies {
        align-items: flex-end;
        margin-top: 0;
    }

    #lang-proficiencies img {
        width: 80%;
        max-width: 23rem;
    }

    #resume-btn {
        align-self: flex-start;
    }

    #about-text {
        margin: 16rem 12% 3.5rem 12%;
    }
}

@media (min-width: 42rem){
    #about-text {
        margin: 15.5rem 13% 3.5rem 13%;
    }
}

@media (min-width: 51rem){
    #about {
        margin-top: 10%;
    }

    #about h3{
        margin-right: 24%;
        margin-left: auto;
    }

    #about-text {
        margin: 14.5rem 14% 3.5rem 14%;
    }
}

@media (min-width: 56rem){
    #about h3{
        margin-right: 22%;
        margin-left: auto;
    }
}

@media (min-width: 66rem){
    #about h3{
        margin-right: 20%;
        margin-left: auto;
    }
}

@media (min-width: 72rem){
    #about h3{
        margin-right: 18%;
        margin-left: auto;
    }
}